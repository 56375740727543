import React, { useState, useRef, useEffect } from "react";
import { Navbar, Nav, NavDropdown, Container, Form, Button, Offcanvas, InputGroup } from "react-bootstrap";
import { Menu as MenuIcon, X, ChevronDown, ChevronRight } from "lucide-react";

const NavigationLink = ({ to, children, className, onClick, isMobile = false }) => {
  const linkRef = useRef(null);

  const handleClick = (e) => {
    if (onClick) onClick(e);
  };

  return (
    <a
      href={to}
      ref={linkRef}
      className={`${className} ${isMobile ? 'mobile-nav-link' : ''}`}
      onClick={handleClick}
    >
      {children}
    </a>
  );
};

const AdaptiveNavDropdown = ({ title, id, children, className, onSelect, isMobile = false }) => {
  const [dropdownDirection, setDropdownDirection] = useState("down");
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (!isMobile) {
      const checkPosition = () => {
        if (dropdownRef.current) {
          const rect = dropdownRef.current.getBoundingClientRect();
          const windowWidth = window.innerWidth;
          
          if (rect.right > windowWidth - 200) {
            setDropdownDirection("start");
          } else {
            setDropdownDirection("down");
          }
        }
      };
      checkPosition();
      window.addEventListener("resize", checkPosition);
      
      return () => {
        window.removeEventListener("resize", checkPosition);
      };
    }
  }, [isMobile]);

  const toggleMobileDropdown = () => {
    setShowMobileDropdown(!showMobileDropdown);
  };

  if (isMobile) {
    return (
      <div className="mobile-dropdown-container">
        <button 
          className="mobile-dropdown-toggle" 
          onClick={toggleMobileDropdown}
          aria-expanded={showMobileDropdown}
        >
          <span>{title}</span>
          {showMobileDropdown ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
        </button>
        {showMobileDropdown && (
          <div className="mobile-dropdown-menu">
            {React.Children.map(children, child => {
              if (child.type === AdaptiveNavDropdown) {
                return React.cloneElement(child, { 
                  isMobile: true,
                  onSelect: (e) => {
                    if (onSelect) onSelect(e);
                  }
                });
              }
              return React.cloneElement(child, { 
                onClick: (e) => {
                  if (onSelect) onSelect(e);
                  if (child.props.onClick) child.props.onClick(e);
                },
                className: "mobile-dropdown-item"
              });
            })}
          </div>
        )}
      </div>
    );
  }

  return (
    <NavDropdown
      ref={dropdownRef}
      title={title}
      id={id}
      className={`${className} no-arrow`}
      drop={dropdownDirection}
      align={dropdownDirection === "start" ? "end" : undefined}
      onSelect={onSelect}
    >
      {children}
    </NavDropdown>
  );
};

const Navigation = () => {
  const [expanded, setExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchModel, setSearchModel] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const navRef = useRef(null);
  const searchRef = useRef(null);
  const collapseTimeoutRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 992) {
        setIsCollapsed(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth > 992) {
        const scrollPosition = window.scrollY;
        if (scrollPosition > 100 && !isCollapsed) {
          setIsCollapsed(true);
        } else if (scrollPosition <= 100 && isCollapsed) {
          setIsCollapsed(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isCollapsed]);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleMouseEnter = () => {
    if (isCollapsed) {
      clearTimeout(collapseTimeoutRef.current);
      setIsHovering(true);
    }
  };

  const handleMouseLeave = () => {
    if (isCollapsed) {
      collapseTimeoutRef.current = setTimeout(() => {
        setIsHovering(false);
      }, 300);
    }
  };

  const closeNavbar = () => {
    setExpanded(false);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      const form = e.target;
      const url = new URL(form.action);
      const params = new URLSearchParams();
      
      params.append('model', searchModel);
      params.append('q[title_cont]', searchQuery);
      params.append('locale', 'en');
      
      window.location.href = `${url.pathname}?${params.toString()}`;
      closeNavbar();
    }
  };

  const mainNavItems = (
    <Nav className="navbar-nav ms-3 shift-left">
      <AdaptiveNavDropdown title="Services" id="servicesDropdown" className="nav-item" style={{ fontSize: "1.5rem !important" }}>
        <NavDropdown.Item as={NavigationLink} to="/divisions/engineering" onClick={closeNavbar}>
          Engineering
        </NavDropdown.Item>
        <NavDropdown.Item as={NavigationLink} to="/divisions/consultations" onClick={closeNavbar}>
          Consultation
        </NavDropdown.Item>
        <NavDropdown.Item as={NavigationLink} to="/divisions/supply-chain" onClick={closeNavbar}>
          Supply Chain
        </NavDropdown.Item>
        <NavDropdown.Item as={NavigationLink} to="/divisions/project-management" onClick={closeNavbar}>
          Project Management
        </NavDropdown.Item>
        <NavDropdown.Item as={NavigationLink} to="/divisions/environment" onClick={closeNavbar}>
          Environment
        </NavDropdown.Item>
        <NavDropdown.Item as={NavigationLink} to="/divisions/research" onClick={closeNavbar}>
          Research
        </NavDropdown.Item>
        <NavDropdown.Item as={NavigationLink} to="/divisions/architecture" onClick={closeNavbar}>
          Architecture
        </NavDropdown.Item>
        <NavDropdown.Item as={NavigationLink} to="/divisions/construction" onClick={closeNavbar}>
          Construction
        </NavDropdown.Item>
        <NavDropdown.Item as={NavigationLink} to="/divisions/training" onClick={closeNavbar}>
          Training
        </NavDropdown.Item>
      </AdaptiveNavDropdown>

      <AdaptiveNavDropdown title="Manufacturing" id="manufacturingDropdown" className="nav-item">
        <NavDropdown.Item as={NavigationLink} to="/manufacturing/facilities" onClick={closeNavbar}>
          Facilities
        </NavDropdown.Item>
        <NavDropdown.Item as={NavigationLink} to="/manufacturing/certification-qualification" onClick={closeNavbar}>
          Certification and Qualification
        </NavDropdown.Item>
      </AdaptiveNavDropdown>

      <AdaptiveNavDropdown title="EPC Projects" id="epcDropdown" className="nav-item">
        <NavDropdown.Item as={NavigationLink} to="/epc_projects/epc-strategy" onClick={closeNavbar}>
          EPC Strategy
        </NavDropdown.Item>
        <NavDropdown.Item as={NavigationLink} to="/epc_projects/detergent-powder-industry" onClick={closeNavbar}>
          Detergent Powder Industry
        </NavDropdown.Item>
        <NavDropdown.Item as={NavigationLink} to="/epc_projects/detergent-liquid-industry" onClick={closeNavbar}>
          Detergent Liquid Industry
        </NavDropdown.Item>
        <NavDropdown.Item as={NavigationLink} to="/epc_projects/sodium-hypochlorite-industry" onClick={closeNavbar}>
          Sodium Hypochlorite Industry
        </NavDropdown.Item>
      </AdaptiveNavDropdown>

      <AdaptiveNavDropdown title="Product" id="productDropdown" className="nav-item">
        <NavDropdown.Item as={NavigationLink} to="/product/1" onClick={closeNavbar}>
          Firefighting - Pumping Unit
        </NavDropdown.Item>
        <NavDropdown.Item as={NavigationLink} to="/product/2" onClick={closeNavbar}>
          Firefighting - Foam Bladder
        </NavDropdown.Item>
        <NavDropdown.Item as={NavigationLink} to="/product/4" onClick={closeNavbar}>
          Mobile Tank System
        </NavDropdown.Item>
        <NavDropdown.Item as={NavigationLink} to="/product/6" onClick={closeNavbar}>
          Vacuum Homogenizer Mixers
        </NavDropdown.Item>
        <NavDropdown.Item as={NavigationLink} to="/product/7" onClick={closeNavbar}>
          Pressure Vessel Systems
        </NavDropdown.Item>
        <NavDropdown.Item as={NavigationLink} to="/product/8" onClick={closeNavbar}>
          Industrial Skid Systems
        </NavDropdown.Item>
        <NavDropdown.Item as={NavigationLink} to="/product/9" onClick={closeNavbar}>
          Structural Steel 
        </NavDropdown.Item>
        <NavDropdown.Item as={NavigationLink} to="/product/11" onClick={closeNavbar}>
          Heat Exchanging
        </NavDropdown.Item>
      </AdaptiveNavDropdown>

      <AdaptiveNavDropdown title="Community" id="communityDropdown" className="nav-item">
        <NavDropdown.Item as={NavigationLink} to="/events/social_contribution" onClick={closeNavbar}>
          Social Contributions
        </NavDropdown.Item>
        <NavDropdown.Item as={NavigationLink} to="/events/social_event" onClick={closeNavbar}>
          Social Events
        </NavDropdown.Item>
      </AdaptiveNavDropdown>

      <AdaptiveNavDropdown title="News and Insights" id="newsDropdown" className="nav-item">
        <NavDropdown.Item as={NavigationLink} to="/posts/press_releases" onClick={closeNavbar}>
          Press Releases
        </NavDropdown.Item>
        <NavDropdown.Item as={NavigationLink} to="/posts/media_reports" onClick={closeNavbar}>
          Media Reports
        </NavDropdown.Item>
        <NavDropdown.Item as={NavigationLink} to="/media_items" onClick={closeNavbar}>
          Media Library
        </NavDropdown.Item>
      </AdaptiveNavDropdown>
    </Nav>
  );

  return (
    <div className="sticky-top" style={{ top: 0 }} id="navbar">
      <Navbar
        ref={navRef}
        expand="lg"
        className={`navbar-light ${isCollapsed ? 'navbar-collapsed' : ''} ${isHovering ? 'navbar-hover' : ''}`}
        expanded={expanded}
        fixed="top"
        onToggle={(isExpanded) => setExpanded(isExpanded)}
        collapseOnSelect
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Container 
          fluid 
          style={{ 
            backgroundColor: "#000B58", 
            height: isCollapsed ? "80px" : "250px",
            transition: "height 0.3s ease",
            position: "relative"
          }}
        >
          <div className="d-flex justify-content-between w-100 align-items-center">
            <div className="d-flex align-items-center">
              <Navbar.Brand as={NavigationLink} to="/" className="d-flex align-items-center" onClick={closeNavbar}>
                <img
                  src="/t/images/logo-mod3.png"
                  id="logo-img"
                  alt="Logo"
                  style={{ 
                    width: isCollapsed ? "80px" : "155px", 
                    height: isCollapsed ? "140px" : "275px",
                    transition: "width 0.3s ease, height 0.3s ease"
                  }}
                />
              </Navbar.Brand>
              <div className="d-flex flex-column ms-2 shift-down" style={{ transition: "all 0.3s ease" }}>
                <div className="text-white">
                  <span style={{ 
                    color: "#FFD700", 
                    fontSize: isCollapsed ? "1.2rem" : "1.5rem", 
                    display: "block",
                    transition: "font-size 0.3s ease"
                  }}>
                    Petroproject
                  </span>
                  <span style={{ 
                    color: "#FFD700", 
                    fontSize: isCollapsed ? "0.9rem" : "1.2rem", 
                    display: "block",
                    transition: "font-size 0.3s ease"
                  }}>
                    International Corporation
                  </span>
                </div>
                <div className="d-none d-lg-block">
                  {!isCollapsed && mainNavItems}
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center">
              {window.innerWidth > 992 && (
                <Button 
                  variant="link" 
                  className="text-white d-lg-block collapse-toggle"
                  onClick={toggleCollapse}
                  aria-label={isCollapsed ? "Expand menu" : "Collapse menu"}
                  style={{
                    position: 'absolute',
                    right: '20px',
                    top: isCollapsed ? '20px' : '20px',
                    zIndex: 1100
                  }}
                >
                  {isCollapsed ? <ChevronDown size={24} /> : <ChevronDown size={24} style={{ transform: 'rotate(180deg)' }} />}
                </Button>
              )}
              <Button 
                variant="link" 
                className="text-white d-lg-none"
                onClick={() => setExpanded(!expanded)}
                aria-label="Menu"
              >
                {expanded ? <X size={24} /> : <MenuIcon size={24} />}
              </Button>
            </div>
          </div>

          <Navbar.Collapse id="navbarMain">
            <Offcanvas 
              show={expanded} 
              onHide={closeNavbar}
              placement="end"
              className="d-lg-none"
              style={{ 
                width: "85vw", 
                backgroundColor: "#000B58",
                zIndex: 1100
              }}
            >
              <Offcanvas.Header closeButton closeVariant="white">
                <Offcanvas.Title className="d-flex align-items-center">
                  <img
                    src="/t/images/logo-mod3.png"
                    alt="Logo"
                    style={{ width: "100px", height: "140px", marginRight: "10px" }}
                  />
                  <div className="text-white">
                    <span style={{ color: "#FFD700", fontSize: "1.2rem", display: "block" }}>Petroproject</span>
                    <span style={{ color: "#FFD700", fontSize: "0.9rem", display: "block" }}>International Corporation</span>
                  </div>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="mobile-nav-body">
                <div className="p-3">
                  <Form 
                    onSubmit={handleSearchSubmit} 
                    method="get" 
                    action="/search"
                    className="d-flex flex-column gap-2 mb-4"
                  >
                    <input type="hidden" name="locale" value="en" />
                    <Form.Select
                      name="model"
                      value={searchModel}
                      onChange={(e) => setSearchModel(e.target.value)}
                      className="w-100"
                      style={{ borderRadius: "0px" }}
                      required
                    >
                      <option value="" disabled>
                        Choose Category
                      </option>
                      <option value="Job">Jobs</option>
                      <option value="Division">Services</option>
                      <option value="Activity">Manufacturing</option>
                      <option value="Project">References</option>
                    </Form.Select>
                    
                    <Form.Control
                      type="search"
                      name="q[title_cont]"
                      ref={searchRef}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder="Type your search..."
                      aria-label="Search"
                      style={{ borderRadius: "0px" }}
                    />
                    
                    <Button 
                      variant="warning" 
                      type="submit"
                      className="w-100"
                      style={{ borderRadius: "0px" }}
                    >
                      Search
                    </Button>
                  </Form>  
                </div>

                <Nav className="flex-column mobile-nav">
                  <div className="mobile-nav-section">
                    <h5 className="mobile-nav-section-title">Corporate</h5>
                    <AdaptiveNavDropdown 
                      title="Profile" 
                      id="mobile-home"
                      isMobile
                      onSelect={closeNavbar}
                    >
                      <NavigationLink to="/le" onClick={closeNavbar} isMobile>
                        at glance
                      </NavigationLink>
                      <NavigationLink to="/core" onClick={closeNavbar} isMobile>
                        core business
                      </NavigationLink>
                      <NavigationLink to="/strategy" onClick={closeNavbar} isMobile>
                        business strategy
                      </NavigationLink>
                      <NavigationLink to="/organization" onClick={closeNavbar} isMobile>
                        organization
                      </NavigationLink>
                    </AdaptiveNavDropdown>
                  </div>

                  <div className="mobile-nav-section">
                    <h5 className="mobile-nav-section-title">Resources</h5>
                    <NavigationLink to="/partners/all" onClick={closeNavbar} isMobile>
                      The Partners
                    </NavigationLink>
                    <NavigationLink to="/customers/list" onClick={closeNavbar} isMobile>
                      The Customers
                    </NavigationLink>
                    <AdaptiveNavDropdown 
                      title="The Reference List" 
                      id="mobile-reference"
                      isMobile
                      onSelect={closeNavbar}
                    >
                      {/* 2024 */}
                      <AdaptiveNavDropdown 
                        title="2024" 
                        id="mobile-2024"
                        isMobile
                        onSelect={closeNavbar}
                      >
                        <NavigationLink to="/projects/revamping-of-spiral-assembly-plant-port-said" onClick={closeNavbar} isMobile>
                          Revamping of spiral assembly Plant. Port-Said
                        </NavigationLink>
                        <NavigationLink to="/projects/oil-module-skid" onClick={closeNavbar} isMobile>
                          Oil Module skid
                        </NavigationLink>
                        <NavigationLink to="/projects/revamping-of-baltim-wastewater-plant" onClick={closeNavbar} isMobile>
                          Revamping of Baltim Wastewater Plant
                        </NavigationLink>
                        <NavigationLink to="/projects/epc-of-wefaq-chemical-plant" onClick={closeNavbar} isMobile>
                          EPC of WEFAQ Chemical Plant
                        </NavigationLink>
                      </AdaptiveNavDropdown>

                      {/* 2023 */}
                      <AdaptiveNavDropdown 
                        title="2023" 
                        id="mobile-2023"
                        isMobile
                        onSelect={closeNavbar}
                      >
                        <NavigationLink to="/projects/faja-heat-exchanger-system" onClick={closeNavbar} isMobile>
                          FAJA Heat Exchanger System
                        </NavigationLink>
                        <NavigationLink to="/projects/al-jabr-detergent-complex" onClick={closeNavbar} isMobile>
                          Al-Jabr Detergent Complex
                        </NavigationLink>
                        <NavigationLink to="/projects/updating-firefighting-systems-for-government-institutions" onClick={closeNavbar} isMobile>
                          Updating firefighting systems for government institutions
                        </NavigationLink>
                      </AdaptiveNavDropdown>

                      {/* 2022 */}
                      <AdaptiveNavDropdown 
                        title="2022" 
                        id="mobile-2022"
                        isMobile
                        onSelect={closeNavbar}
                      >
                        <NavigationLink to="/projects/revamping-of-tafahna-alazab-sanitation-plant" onClick={closeNavbar} isMobile>
                          Revamping of Tafahna-Alazab Sanitation Plant
                        </NavigationLink>
                        <NavigationLink to="/projects/jib-crane-and-debris-cage-maintenance-works" onClick={closeNavbar} isMobile>
                          Jib-Crane and debris cage maintenance works
                        </NavigationLink>
                        <NavigationLink to="/projects/taaqim-detergent-factory" onClick={closeNavbar} isMobile>
                          Taaqim Detergent Factory
                        </NavigationLink>
                      </AdaptiveNavDropdown>

                      {/* Continue with remaining years in reverse order... */}
                      {/* 2021 */}
                      <AdaptiveNavDropdown 
                        title="2021" 
                        id="mobile-2021"
                        isMobile
                        onSelect={closeNavbar}
                      >
                        <NavigationLink to="/projects/revamping-of-alharga-sanitation-plant" onClick={closeNavbar} isMobile>
                          Revamping of ALHARGA Sanitation Plant
                        </NavigationLink>
                        <NavigationLink to="/projects/revamping-of-monshaat-taher-sanitation-plant" onClick={closeNavbar} isMobile>
                          Revamping of MONSHAAT TAHER Sanitation Plant
                        </NavigationLink>
                        <NavigationLink to="/projects/steel-structure-fabrication" onClick={closeNavbar} isMobile>
                          Steel Structure Fabrication
                        </NavigationLink>
                        <NavigationLink to="/projects/epc-of-keir-mazareana-cheese-factory" onClick={closeNavbar} isMobile>
                          EPC of KEIR MAZAREANA Cheese Factory
                        </NavigationLink>
                      </AdaptiveNavDropdown>

                      {/* 2020 */}
                      <AdaptiveNavDropdown 
                        title="2020" 
                        id="mobile-2020"
                        isMobile
                        onSelect={closeNavbar}
                      >
                        <NavigationLink to="/projects/liquid-detergent-plant-continues-system" onClick={closeNavbar} isMobile>
                          Liquid Detergent Plant [Continues System]
                        </NavigationLink>
                        <NavigationLink to="/projects/stainless-steel-heat-exchanger-unit" onClick={closeNavbar} isMobile>
                          Stainless Steel Heat Exchanger unit
                        </NavigationLink>
                      </AdaptiveNavDropdown>

                      {/* 2019 */}
                      <AdaptiveNavDropdown 
                        title="2019" 
                        id="mobile-2019"
                        isMobile
                        onSelect={closeNavbar}
                      >
                        <NavigationLink to="/projects/powder-detergent-plant-capacity-10-ton-per-hour" onClick={closeNavbar} isMobile>
                          Powder Detergent Plant Capacity 10 ton per Hour
                        </NavigationLink>
                        <NavigationLink to="/projects/liquid-detergent-plant-capacity-6000-litters-per-hour" onClick={closeNavbar} isMobile>
                          Liquid Detergent Plant, Capacity 6000 Litters Per Hour
                        </NavigationLink>
                        <NavigationLink to="/projects/boiler-and-steam-system-rehabilitation" onClick={closeNavbar} isMobile>
                          Boiler and Steam System Rehabilitation
                        </NavigationLink>
                        <NavigationLink to="/projects/sodium-hypochlorite-production-unit-10-ton-batch" onClick={closeNavbar} isMobile>
                          Sodium Hypochlorite Production unit [10 ton/batch]
                        </NavigationLink>
                      </AdaptiveNavDropdown>

                      {/* 2018 */}
                      <AdaptiveNavDropdown 
                        title="2018" 
                        id="mobile-2018"
                        isMobile
                        onSelect={closeNavbar}
                      >
                        <NavigationLink to="/projects/no-210-oil-gas-modular-skid" onClick={closeNavbar} isMobile>
                          No. 210 Oil & Gas Modular Skid
                        </NavigationLink>
                        <NavigationLink to="/projects/flow-assurance-and-hydraulic-study-for-250-oil-well" onClick={closeNavbar} isMobile>
                          Flow Assurance and Hydraulic study for 250 oil well
                        </NavigationLink>
                        <NavigationLink to="/projects/flow-assurance-and-pipelines-integration-studies-for-250-crude-oil-well-bore" onClick={closeNavbar} isMobile>
                          Flow Assurance and pipelines integration studies for 250 crude oil well bore
                        </NavigationLink>
                        <NavigationLink to="/projects/pha-and-psm-studies-and-review" onClick={closeNavbar} isMobile>
                          PHA and PSM studies and review
                        </NavigationLink>
                        <NavigationLink to="/projects/port-sudan-refinery-plant-rehabilitation-capacity-50-000-bbl-day" onClick={closeNavbar} isMobile>
                          Port Sudan Refinery Plant, Rehabilitation Capacity 50,000 bbl/day
                        </NavigationLink>
                        <NavigationLink to="/projects/fuel-products-tank-farm" onClick={closeNavbar} isMobile>
                          Fuel products tank farm
                        </NavigationLink>
                      </AdaptiveNavDropdown>

                      {/* 2017 */}
                      <AdaptiveNavDropdown 
                        title="2017" 
                        id="mobile-2017"
                        isMobile
                        onSelect={closeNavbar}
                      >
                        <NavigationLink to="/projects/architecture-engineering-for-new-palace-2500-square-meter" onClick={closeNavbar} isMobile>
                          Architecture engineering for new palace, 2500 square meter
                        </NavigationLink>
                        <NavigationLink to="/projects/architecture-engineering-for-no-6-villa-1100-sq-mt-per-each" onClick={closeNavbar} isMobile>
                          Architecture engineering for no. 6 villa 1100 sq. mt. Per each
                        </NavigationLink>
                        <NavigationLink to="/projects/asset-integrity-management-and-hazop" onClick={closeNavbar} isMobile>
                          Asset integrity management and HAZOP
                        </NavigationLink>
                        <NavigationLink to="/projects/i1600-sq-mt-steel-structure-workshop-o-h-crane-10-ton" onClick={closeNavbar} isMobile>
                          I1600 sq. Mt. Steel structure workshop, O.H. Crane 10 ton
                        </NavigationLink>
                      </AdaptiveNavDropdown>

                      {/* 2016 */}
                      <AdaptiveNavDropdown 
                        title="2016" 
                        id="mobile-2016"
                        isMobile
                        onSelect={closeNavbar}
                      >
                        <NavigationLink to="/projects/three-phase-separator-no-2-unit-capacity-25k-bbl" onClick={closeNavbar} isMobile>
                          Three phase separator, no. 2 unit, capacity 25k bbl
                        </NavigationLink>
                        <NavigationLink to="/projects/terminal-storage-tank-no-2-floating-roof-tank-dia-89mt" onClick={closeNavbar} isMobile>
                          Terminal / storage tank, no. 2 floating roof tank, dia. 89mt
                        </NavigationLink>
                        <NavigationLink to="/projects/sil-safety-integrated-level-and-hazop" onClick={closeNavbar} isMobile>
                          SIL, Safety Integrated Level And HAZOP
                        </NavigationLink>
                      </AdaptiveNavDropdown>

                      {/* 2015 */}
                      <AdaptiveNavDropdown 
                        title="2015" 
                        id="mobile-2015"
                        isMobile
                        onSelect={closeNavbar}
                      >
                        <NavigationLink to="/projects/cs-washing-storage-tank-5000-cubic-meter-per-each" onClick={closeNavbar} isMobile>
                          CS. Washing / storage tank 5000 cubic meter per each
                        </NavigationLink>
                        <NavigationLink to="/projects/desludging-and-cleaning-for-storage-tank-1-dia-50mt" onClick={closeNavbar} isMobile>
                          Desludging and cleaning for storage tank# 1, dia. 50mt
                        </NavigationLink>
                      </AdaptiveNavDropdown>

                      {/* 2014 */}
                      <AdaptiveNavDropdown 
                        title="2014" 
                        id="mobile-2014"
                        isMobile
                        onSelect={closeNavbar}
                      >
                        <NavigationLink to="/projects/chemical-fuel-st-st-storage-tank-farm-no-4-tank" onClick={closeNavbar} isMobile>
                          Chemical / fuel St. St. Storage tank farm, no. 4 tank
                        </NavigationLink>
                        <NavigationLink to="/projects/ug-gre-water-injection-pipeline-size-8-inch-length-10-km" onClick={closeNavbar} isMobile>
                          UG. GRE water injection pipeline, size 8-inch, length 10 km
                        </NavigationLink>
                      </AdaptiveNavDropdown>

                      {/* 2013 */}
                      <AdaptiveNavDropdown 
                        title="2013" 
                        id="mobile-2013"
                        isMobile
                        onSelect={closeNavbar}
                      >
                        <NavigationLink to="/projects/ug-st-st-chemical-pipeline-size-6-inch-length-6-km" onClick={closeNavbar} isMobile>
                          UG. St. St. Chemical pipeline, size 6-inch, length 6 km
                        </NavigationLink>
                      </AdaptiveNavDropdown>
                    </AdaptiveNavDropdown>
                    <NavigationLink to="/sustainabilities/Sustainability" onClick={closeNavbar} isMobile>
                      Sustainability
                    </NavigationLink>
                  </div>

                  <div className="mobile-nav-section">
                    <h5 className="mobile-nav-section-title">Services & Products</h5>
                    {React.Children.map(mainNavItems.props.children, (dropdown) => {
                      return React.cloneElement(dropdown, {
                        isMobile: true,
                        onSelect: closeNavbar
                      });
                    })}
                  </div>
                </Nav>
              </Offcanvas.Body>
            </Offcanvas>

            <div className="d-none d-lg-block col-lg-12">
              <div 
                className={`navbar-row row mt-2 mt-md-0 justify-content-end shift-up ${isCollapsed ? 'collapsed' : ''}`}
                style={{
                  opacity: isCollapsed ? (isHovering ? 1 : 0) : 1,
                  height: isCollapsed ? (isHovering ? 'auto' : '0') : 'auto',
                  overflow: isCollapsed ? (isHovering ? 'visible' : 'hidden') : 'visible',
                  transition: 'opacity 0.3s ease, height 0.3s ease',
                  position: isCollapsed ? 'absolute' : 'relative',
                  width: '100%',
                  backgroundColor: '#000B58',
                  zIndex: 1000,
                  top: isCollapsed ? '80px' : '0',
                  left: '0'
                }}
              >
                <div className="col-auto">
                  <Nav className="navbar-nav">
                    <AdaptiveNavDropdown title="Capabilities" id="capabilitiesDropdown" className="nav-item">
                      <NavDropdown.Item as={NavigationLink} to="/partners/all" onClick={closeNavbar}>
                        The Partners
                      </NavDropdown.Item>
                      <NavDropdown.Item as={NavigationLink} to="/customers/list" onClick={closeNavbar}>
                        The Customers
                      </NavDropdown.Item>
                      <AdaptiveNavDropdown title="The Reference List" id="referenceDropdown" className="dropdown">
                      {/* 2024 */}
                      <AdaptiveNavDropdown title="2024" id="year2024Dropdown" className="dropdown">
                        <NavDropdown.Item as={NavigationLink} to="/projects/revamping-of-spiral-assembly-plant-port-said" onClick={closeNavbar}>
                          Revamping of spiral assembly Plant. Port-Said
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/oil-module-skid" onClick={closeNavbar}>
                          Oil Module skid
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/revamping-of-baltim-wastewater-plant" onClick={closeNavbar}>
                          Revamping of Baltim Wastewater Plant
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/epc-of-wefaq-chemical-plant" onClick={closeNavbar}>
                          EPC of WEFAQ Chemical Plant
                        </NavDropdown.Item>
                      </AdaptiveNavDropdown>

                      {/* 2023 */}
                      <AdaptiveNavDropdown title="2023" id="year2023Dropdown" className="dropdown">
                        <NavDropdown.Item as={NavigationLink} to="/projects/faja-heat-exchanger-system" onClick={closeNavbar}>
                          FAJA Heat Exchanger System
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/al-jabr-detergent-complex" onClick={closeNavbar}>
                          Al-Jabr Detergent Complex
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/updating-firefighting-systems-for-government-institutions" onClick={closeNavbar}>
                          Updating firefighting systems for government institutions
                        </NavDropdown.Item>
                      </AdaptiveNavDropdown>

                      {/* 2022 */}
                      <AdaptiveNavDropdown title="2022" id="year2022Dropdown" className="dropdown">
                        <NavDropdown.Item as={NavigationLink} to="/projects/revamping-of-tafahna-alazab-sanitation-plant" onClick={closeNavbar}>
                          Revamping of Tafahna-Alazab Sanitation Plant
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/jib-crane-and-debris-cage-maintenance-works" onClick={closeNavbar}>
                          Jib-Crane and debris cage maintenance works
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/taaqim-detergent-factory" onClick={closeNavbar}>
                          Taaqim Detergent Factory
                        </NavDropdown.Item>
                      </AdaptiveNavDropdown>

                      {/* 2021 */}
                      <AdaptiveNavDropdown title="2021" id="year2021Dropdown" className="dropdown">
                        <NavDropdown.Item as={NavigationLink} to="/projects/revamping-of-alharga-sanitation-plant" onClick={closeNavbar}>
                          Revamping of ALHARGA Sanitation Plant
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/revamping-of-monshaat-taher-sanitation-plant" onClick={closeNavbar}>
                          Revamping of MONSHAAT TAHER Sanitation Plant
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/steel-structure-fabrication" onClick={closeNavbar}>
                          Steel Structure Fabrication
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/epc-of-keir-mazareana-cheese-factory" onClick={closeNavbar}>
                          EPC of KEIR MAZAREANA Cheese Factory
                        </NavDropdown.Item>
                      </AdaptiveNavDropdown>

                      {/* Continue with remaining years in reverse order... */}
                      {/* 2020 */}
                      <AdaptiveNavDropdown title="2020" id="year2020Dropdown" className="dropdown">
                        <NavDropdown.Item as={NavigationLink} to="/projects/liquid-detergent-plant-continues-system" onClick={closeNavbar}>
                          Liquid Detergent Plant [Continues System]
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/stainless-steel-heat-exchanger-unit" onClick={closeNavbar}>
                          Stainless Steel Heat Exchanger unit
                        </NavDropdown.Item>
                      </AdaptiveNavDropdown>

                      {/* 2019 */}
                      <AdaptiveNavDropdown title="2019" id="year2019Dropdown" className="dropdown">
                        <NavDropdown.Item as={NavigationLink} to="/projects/powder-detergent-plant-capacity-10-ton-per-hour" onClick={closeNavbar}>
                          Powder Detergent Plant Capacity 10 ton per Hour
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/liquid-detergent-plant-capacity-6000-litters-per-hour" onClick={closeNavbar}>
                          Liquid Detergent Plant, Capacity 6000 Litters Per Hour
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/boiler-and-steam-system-rehabilitation" onClick={closeNavbar}>
                          Boiler and Steam System Rehabilitation
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/sodium-hypochlorite-production-unit-10-ton-batch" onClick={closeNavbar}>
                          Sodium Hypochlorite Production unit [10 ton/batch]
                        </NavDropdown.Item>
                      </AdaptiveNavDropdown>

                      {/* 2018 */}
                      <AdaptiveNavDropdown title="2018" id="year2018Dropdown" className="dropdown">
                        <NavDropdown.Item as={NavigationLink} to="/projects/no-210-oil-gas-modular-skid" onClick={closeNavbar}>
                          No. 210 Oil & Gas Modular Skid
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/flow-assurance-and-hydraulic-study-for-250-oil-well" onClick={closeNavbar}>
                          Flow Assurance and Hydraulic study for 250 oil well
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/flow-assurance-and-pipelines-integration-studies-for-250-crude-oil-well-bore" onClick={closeNavbar}>
                          Flow Assurance and pipelines integration studies for 250 crude oil well bore
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/pha-and-psm-studies-and-review" onClick={closeNavbar}>
                          PHA and PSM studies and review
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/port-sudan-refinery-plant-rehabilitation-capacity-50-000-bbl-day" onClick={closeNavbar}>
                          Port Sudan Refinery Plant, Rehabilitation Capacity 50,000 bbl/day
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/fuel-products-tank-farm" onClick={closeNavbar}>
                          Fuel products tank farm
                        </NavDropdown.Item>
                      </AdaptiveNavDropdown>

                      {/* 2017 */}
                      <AdaptiveNavDropdown title="2017" id="year2017Dropdown" className="dropdown">
                        <NavDropdown.Item as={NavigationLink} to="/projects/architecture-engineering-for-new-palace-2500-square-meter" onClick={closeNavbar}>
                          Architecture engineering for new palace, 2500 square meter
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/architecture-engineering-for-no-6-villa-1100-sq-mt-per-each" onClick={closeNavbar}>
                          Architecture engineering for no. 6 villa 1100 sq. mt. Per each
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/asset-integrity-management-and-hazop" onClick={closeNavbar}>
                          Asset integrity management and HAZOP
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/i1600-sq-mt-steel-structure-workshop-o-h-crane-10-ton" onClick={closeNavbar}>
                          I1600 sq. Mt. Steel structure workshop, O.H. Crane 10 ton
                        </NavDropdown.Item>
                      </AdaptiveNavDropdown>

                      {/* 2016 */}
                      <AdaptiveNavDropdown title="2016" id="year2016Dropdown" className="dropdown">
                        <NavDropdown.Item as={NavigationLink} to="/projects/three-phase-separator-no-2-unit-capacity-25k-bbl" onClick={closeNavbar}>
                          Three phase separator, no. 2 unit, capacity 25k bbl
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/terminal-storage-tank-no-2-floating-roof-tank-dia-89mt" onClick={closeNavbar}>
                          Terminal / storage tank, no. 2 floating roof tank, dia. 89mt
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/sil-safety-integrated-level-and-hazop" onClick={closeNavbar}>
                          SIL, Safety Integrated Level And HAZOP
                        </NavDropdown.Item>
                      </AdaptiveNavDropdown>

                      {/* 2015 */}
                      <AdaptiveNavDropdown title="2015" id="year2015Dropdown" className="dropdown">
                        <NavDropdown.Item as={NavigationLink} to="/projects/cs-washing-storage-tank-5000-cubic-meter-per-each" onClick={closeNavbar}>
                          CS. Washing / storage tank 5000 cubic meter per each
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/desludging-and-cleaning-for-storage-tank-1-dia-50mt" onClick={closeNavbar}>
                          Desludging and cleaning for storage tank# 1, dia. 50mt
                        </NavDropdown.Item>
                      </AdaptiveNavDropdown>

                      {/* 2014 */}
                      <AdaptiveNavDropdown title="2014" id="year2014Dropdown" className="dropdown">
                        <NavDropdown.Item as={NavigationLink} to="/projects/chemical-fuel-st-st-storage-tank-farm-no-4-tank" onClick={closeNavbar}>
                          Chemical / fuel St. St. Storage tank farm, no. 4 tank
                        </NavDropdown.Item>
                        <NavDropdown.Item as={NavigationLink} to="/projects/ug-gre-water-injection-pipeline-size-8-inch-length-10-km" onClick={closeNavbar}>
                          UG. GRE water injection pipeline, size 8-inch, length 10 km
                        </NavDropdown.Item>
                      </AdaptiveNavDropdown>

                      {/* 2013 */}
                      <AdaptiveNavDropdown title="2013" id="year2013Dropdown" className="dropdown">
                        <NavDropdown.Item as={NavigationLink} to="/projects/ug-st-st-chemical-pipeline-size-6-inch-length-6-km" onClick={closeNavbar}>
                          UG. St. St. Chemical pipeline, size 6-inch, length 6 km
                        </NavDropdown.Item>
                      </AdaptiveNavDropdown>
                    </AdaptiveNavDropdown>
                    </AdaptiveNavDropdown>

                    <AdaptiveNavDropdown 
                      title="Sustainability" 
                      id="sustainabilityDropdown" 
                      className="nav-item force-no-arrow"
                    >
                      <NavDropdown.Item as={NavigationLink} to="/sustainabilities/Sustainability" onClick={closeNavbar}>
                        Sustainability
                      </NavDropdown.Item>
                    </AdaptiveNavDropdown>

                    <AdaptiveNavDropdown 
                      title="Corporate Profile" 
                      id="moreDropdown" 
                      className="nav-item no-arrow"
                    >
                      <NavDropdown.Item as={NavigationLink} to="/le" onClick={closeNavbar}>
                        at glance
                      </NavDropdown.Item>
                      <NavDropdown.Item as={NavigationLink} to="/core" onClick={closeNavbar}>
                        core business
                      </NavDropdown.Item>
                      <NavDropdown.Item as={NavigationLink} to="/strategy" onClick={closeNavbar}>
                        business strategy
                      </NavDropdown.Item>
                      <NavDropdown.Item as={NavigationLink} to="/organization" onClick={closeNavbar}>
                        organization
                      </NavDropdown.Item>
                    </AdaptiveNavDropdown>
                  </Nav>
                </div>

                <div className="col-auto">
                  <Form 
                    className="form-inline d-flex shift-left-search" 
                    onSubmit={handleSearchSubmit}
                    method="get" 
                    action="/search"
                  >
                    <input type="hidden" name="locale" value="en" />
                    <InputGroup>
                      <Form.Select
                        name="model"
                        value={searchModel}
                        onChange={(e) => setSearchModel(e.target.value)}
                        className="me-2"
                        style={{ width: "105px", height: "36px", borderRadius: "0px 0 0 0px" }}
                        required
                      >
                        <option value="" disabled>
                          Category
                        </option>
                        <option value="Job">Jobs</option>
                        <option value="Division">Services</option>
                        <option value="Activity">Manufacturing</option>
                        <option value="Project">References</option>
                      </Form.Select>

                      <Form.Control
                        type="search"
                        name="q[title_cont]"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search..."
                        aria-label="Search"
                        style={{ width: "105px", height: "36px", borderRadius: "0 0px 0px 0", marginLeft: "10px" }}
                      />

                      <Button 
                        variant="warning" 
                        type="submit" 
                        style={{ borderRadius: "0 0px 0px 0", height: "36px", marginLeft: "10px" }}
                      >
                        Search
                      </Button>
                    </InputGroup>
                  </Form>
                </div>
              </div>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <style jsx global>{`
        /* Base styles for nav items and dropdowns */
        .nav-item {
          background: none !important;
        }
        
        .dropdown-menu {
          background-color: #000B58 !important;
          padding: 0;
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
          border-radius: 0 !important;
        }
        
        input::placeholder {
          color: black !important;
        }

        .dropdown-menu.show {
          background-color: #000B58 !important;
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
          border-radius: 0 !important;
        }
        
        .dropdown-item {
          color: white !important;
          padding: 0.5rem 1rem !important;
          transition: all 0.2s ease;
        }
        
        .dropdown-item:hover, 
        .dropdown-item:focus {
          color: #FFD700 !important;
          background-color: rgba(255, 215, 0, 0.1) !important;
        }
        
        .dropdown-divider {
          border-top: 1px solid rgba(255, 215, 0, 0.3) !important;
          margin: 0.25rem 0;
        }
        
        .no-arrow .dropdown-toggle::after {
          display: none !important;
        }
        
        /* Mobile specific styles */
        .mobile-nav-body {
          padding: 0;
        }
        
        .mobile-nav {
          padding: 0 16px;
        }
        
        .mobile-nav-section {
          margin-bottom: 24px;
        }
        
        .mobile-nav-section-title {
          color: #FFD700;
          font-size: 0.9rem;
          text-transform: uppercase;
          letter-spacing: 1px;
          margin-bottom: 12px;
          padding-bottom: 4px;
          border-bottom: 1px solid rgba(255, 215, 0, 0.3);
        }
        
        .mobile-dropdown-container {
          margin-bottom: 8px;
        }
        
        .mobile-dropdown-toggle {
          background: none;
          border: none;
          color: white;
          width: 100%;
          text-align: left;
          padding: 12px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 1rem;
        }
        
        .mobile-dropdown-toggle:hover {
          color: #FFD700;
        }
        
        .mobile-dropdown-menu {
          padding-left: 16px;
          margin-bottom: 8px;
          background: none !important;
        }
        
        .mobile-dropdown-item, 
        .mobile-nav-link {
          display: block;
          color: white;
          padding: 10px 0;
          text-decoration: none;
          background: none;
          border: none;
          width: 100%;
          text-align: left;
        }
        
        .mobile-dropdown-item:hover, 
        .mobile-nav-link:hover {
          color: #FFD700;
          background-color: rgba(255, 215, 0, 0.1);
        }
        
        /* Z-index management */
        .navbar-light {
          z-index: 1100;
        }
        
        .navbar-light .btn-link {
          z-index: 1100;
          position: relative;
        }
        
        .offcanvas-backdrop {
          z-index: 1098 !important;
        }
        
        /* Form controls */
        .form-control, 
        .form-select {
          touch-action: manipulation;
          background-color: #fff !important;
        }
        
        /* Offcanvas positioning */
        .offcanvas {
          z-index: 1100 !important;
        }
        
        .navbar-collapse {
          z-index: 1101 !important;
        }
        
        /* Navbar link colors */
        .navbar-light .navbar-nav .nav-link {
          color: white;
        }
        
        .navbar-light .navbar-nav .nav-link:hover,
        .navbar-light .navbar-nav .nav-link:focus {
          color: #FFD700;
        }
        
        .navbar-light .navbar-nav .show > .nav-link,
        .navbar-light .navbar-nav .active > .nav-link,
        .navbar-light .navbar-nav .nav-link.show,
        .navbar-light .navbar-nav .nav-link.active {
          color: #FFD700;
        }
        
        /* Remove background from the Reference List dropdown parent */
        .dropdown.no-arrow.nav-item.show.dropstart {
          background: white !important;
        }
        
        /* Default state (collapsed) */
        #referenceDropdown.dropdown-toggle.nav-link[aria-expanded="false"] {
          background: white !important;
          color: black !important;
        }
        
        /* Hover state (collapsed) */
        #referenceDropdown.dropdown-toggle.nav-link[aria-expanded="false"]:hover {
          background: rgb(0, 11, 88) !important;
          color: white !important;
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
          border-radius: 0 !important;
        }
        
        /* Active/expanded state */
        #referenceDropdown.dropdown-toggle.show.nav-link[aria-expanded="true"] {
          background-color: rgb(0, 11, 88) !important;
          color: white !important;
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
          border-radius: 0 !important;
        }
        
        /* Hover state (expanded) */
        #referenceDropdown.dropdown-toggle.show.nav-link[aria-expanded="true"]:hover {
          background-color: rgb(210, 40, 40) !important;
          color: white !important;
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
          border-radius: 0 !important;
        }
        
        /* Focus state */
        #referenceDropdown.dropdown-toggle.nav-link:focus {
          outline: 2px solid rgba(255, 215, 0, 0.5) !important;
        }
        
        /* Remove hover background */
        #referenceDropdown.nav-link:hover,
        #referenceDropdown.nav-link:focus {
          background: transparent !important;
          color: #FFD700 !important;
        }
        
        /* Remove active state background */
        #referenceDropdown.nav-link.show {
          background: transparent !important;
          color:rgb(19, 18, 17) !important;
        }
        
        /* Fix for the dropdown menu container */
        .dropdown-menu .dropdown-menu {
          background: rgb(0, 11, 88) !important;
          box-shadow: none !important;
          border-radius: 0 !important;
        }
        
        .shift-down {
          position: relative;
          top: 65px;
        }
        
        .shift-up {
          position: relative;
          top: -90px;
        }
        
        .shift-left {
          position: relative;
          left: -24px;
        }
        
        .shift-left-search {
          position: relative;
          left: -8px;
        }
        
        #navbar {
          .force-no-arrow {
            .dropdown-toggle::after {
              display: none !important;
              content: "" !important;
            }
            
            &.show .dropdown-toggle::after {
              display: none !important;
            }
            
            .dropdown-toggle.nav-link::after {
              display: none !important;
            }
          }
          
          /* Handle Bootstrap's different dropdown implementations */
          .nav-link.dropdown-toggle::after,
          .btn.dropdown-toggle::after {
            display: none !important;
          }
        }
        
        .text-large { font-size: 1.25rem; } 
        .text-larger { font-size: 1.5rem; }
        .text-xl { font-size: 2rem; } 
        .text-xxl { font-size: 3rem; }
        
        /* Collapse toggle button styles */
        .collapse-toggle {
          transition: all 0.3s ease;
          opacity: 0.7;
        }
        
        .collapse-toggle:hover {
          opacity: 1;
          transform: scale(1.1);
        }
        
        /* Navbar hover state */
        .navbar-collapsed.navbar-hover {
          height: auto !important;
        }
        
        /* Smooth transitions */
        .navbar-collapsed {
          transition: height 0.3s ease, background-color 0.3s ease;
        }
        
        .navbar-collapsed .shift-down {
          top: 20px;
          transition: all 0.3s ease;
        }
        
        .navbar-collapsed #logo-img {
          transition: width 0.3s ease, height 0.3s ease;
        }
      `}</style>
    </div>
  );
};

const Layout = () => {
  return (
    <div>
      <Navigation />
      <Container className="mt-4">
        <div id="rails-flash-messages" dangerouslySetInnerHTML={{ __html: document.getElementById("flash-messages")?.innerHTML || "" }} />
        <div id="rails-yield" dangerouslySetInnerHTML={{ __html: document.getElementById("rails-yield")?.innerHTML || "" }} />
      </Container>
    </div>
  );
};

document.addEventListener("DOMContentLoaded", () => {
  const navbarContainer = document.getElementById("navbar");
  if (navbarContainer) {
    import("react-dom/client").then(({ createRoot }) => {
      createRoot(navbarContainer).render(<Layout />);
    });
  } else {
    console.error("Navbar container not found!");
  }
});

export default Layout;